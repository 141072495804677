@define-mixin m-clearFix {
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }
}
@define-mixin m-large {
  @media (min-width: 1024px){
    @mixin-content;
  }
}
@define-mixin m-middle {
  @media (min-width: 768px) , print {
    @mixin-content;
  }
}
@define-mixin m-xsmax {
  @media (max-width: 575px) {
    @mixin-content;
  }
}
@define-mixin m-inner {
  width: var(--base-pc-width);
  margin: 0 auto;
  padding: 0 var(--base-pc-padding);
}

@define-mixin m-inner-big $width:var(--base-pc-width) {
  width: $width;
  margin: 0 auto;
  padding: 0 var(--base-pc-padding);
}

@define-mixin m-inner-min {
  width: var(--base-pc-width-min);
  margin: 0 auto;
}

@define-mixin m-innersp {
  padding: 0 var(--base-sp-padding);
}

/* center , flex-end , flex-start , space-around ,*/
@define-mixin m-flexset $justify:space-between, $wrap:nowrap {
  display: flex;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@define-mixin m-flexbase $size {
  flex-basis: $size;
  max-width: $size;
}

@define-mixin m-absolute $top,$right,$bottom,$left {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@define-mixin m-anchor-pc $width: 100%,$fontColor: #000 {
  ul {
    width: $width;
    li {
      a {
        padding-bottom: 20px;
        &:after {
          bottom: 5px;
          transition: all var(--base-animespeed);
        }
        &:hover:after {
          bottom: 0;
        }
      }
    }
  }
}
@define-mixin m-anchor-sp $width: calc(600/375*100vw),$fontColor: #000 {
  overflow-y: hidden !important;
  ul {
    @mixin m-flexset space-around,nowrap;
    list-style: none;
    width: $width;
    li {
      @mixin m-flexset center,nowrap;
      align-items: stretch;
      a {
        text-align: center;
        color: $fontColor;
        padding: 0 .5em calc(15/375*100vw);
        position: relative;
        @mixin m-flexset center,wrap;
        align-items: center;
        p {
          width: 100%;
        }
        &:after {
          content: url("/common/image/icon-arrow-dwon-red.svg");
          @mixin m-absolute auto,auto,0,50%;
          transform: translateX(-50%);
        }
      }
    }
  }

}

/* ボタン  */
@define-mixin m-more-btn-small $color: var(--color-primary), $hovercolor: var(--button-text-hover) ,$hoverbgcolor: var(--color-primary) {
  display: inline-block;
  position: relative;
  text-align: left;
  padding: .9em 0 .7em;
  line-height: 1;

  span {
    color: $color;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed) var(--animate-easeinout);

  }
  svg {
    fill: $color;
    margin-left: .5em;
    transition: all var(--base-animespeed);
    width: .8em;
    height: .8em;
  }

  i {
    display: block;
    background-color: $color;
    height: 1px;
    width: 23px;
    position: absolute;
    top: 49%;
    right: 5%;
    transition: all var(--base-animespeed) var(--animate-easeinout);
    &:before {
      content: '';
      background-color: $color;
      height: 1px;
      width: 5px;
      position: absolute;
      bottom: 2px;
      right: 0;
      transition: all var(--base-animespeed) var(--animate-easeinout);
      transform: rotate(45deg);
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;
    transition: width var(--base-animespeed) var(--animate-easeinout);

  }
  a:hover & , a.is-hover & {
    text-align: center;

    span {
      color: $hovercolor;
    }
    svg {
      fill: $hovercolor;
    }
    i {
      background-color: $hovercolor;
      right: -5px;

      &:before {
        background-color: $hovercolor;
      }
    }
    &:before {
      width: 100%;
    }
  }
}

/* ボタン - 日本語の方  */
@define-mixin m-more-btn-small-jp $color: var(--color-primary), $hovercolor: var(--button-text-hover) ,$hoverbgcolor: var(--color-primary) {
  display: inline-block;
  position: relative;
  text-align: left;
  padding: .8em 0;
  line-height: 1;

  span {
    color: $color;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed) var(--animate-easeinout);
    @mixin m-flexset flex-start,nowrap;
    align-items: center;
  }

  svg {
    fill: $color;
    margin-left: .5em;
    transition: all var(--base-animespeed);
    width: .8em;
    height: .8em;
  }

  i {
    display: block;
    background-color: $color;
    height: 1px;
    width: 23px;
    position: absolute;
    top: 49%;
    right: 5%;
    transition: all var(--base-animespeed) var(--animate-easeinout);

    &:before {
      content: '';
      background-color: $color;
      height: 1px;
      width: 5px;
      position: absolute;
      bottom: 2px;
      right: 0;
      transition: all var(--base-animespeed) var(--animate-easeinout);
      transform: rotate(45deg);
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;
    transition: width var(--base-animespeed) var(--animate-easeinout);
  }

  a:hover & , a.is-hover & {
    text-align: center;

    span {
      color: $hovercolor;
      justify-content: center;
    }
    svg {
      fill: $hovercolor;
    }
    i {
      background-color: $hovercolor;
      right: -5px;

      &:before {
        background-color: $hovercolor;
      }
    }
    &:before {
      width: 100%;
    }
  }
}

/* スマホ用ホバー固定ボタン */
@define-mixin m-more-btn-small-hover $hovercolor: var(--color-primary) ,$hoverbgcolor: var(--button-text-hover){
  display: inline-block;
  position: relative;
  font-size: calc(12/375*100)vw;
  /* text-align: left; */
  padding: calc(9/375*100)vw calc(20/375*100)vw calc(6/375*100)vw 0;
  text-align: center;
  line-height: 1;
  text-decoration: none;

  span {
    color: $hovercolor;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed) var(--animate-easeinout);

  }
  svg {
    fill: $hovercolor;
    margin-left: .5em;
    width: .8em;
    height: .8em;
  }

  i {
    display: block;
    background-color: $hovercolor;
    height: calc(1/375*100)vw;
    width: 17%;
    position: absolute;
    top: 49%;
    right: 5%;
    transition: all var(--base-animespeed) var(--animate-easeinout);

    &:before {
      content: '';
      background-color: $hovercolor;
      height: calc(1/375*100)vw;
      width: 20%;
      position: absolute;
      bottom: 200%;
      right: 0;
      transform: rotate(45deg);


    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;

    transition: width var(--base-animespeed) var(--animate-easeinout);

  }
}

@define-mixin m-more-btn-small-jp-hover $hovercolor: var(--color-primary) ,$hoverbgcolor: var(--button-text-hover){
  display: inline-block;
  position: relative;
  font-size: calc(12/375*100)vw;
  /* text-align: left; */
  padding: calc(8/375*100)vw calc(20/375*100)vw calc(8/375*100)vw 0;
  text-align: center;
  line-height: 1;
  text-decoration: none;

  span {
    color: $hovercolor;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed) var(--animate-easeinout);

  }
  svg {
    fill: $hovercolor;
    margin-left: .5em;
    width: .8em;
    height: .8em;
  }

  i {
    display: block;
    background-color: $hovercolor;
    height: calc(1/375*100)vw;
    width: 17%;
    position: absolute;
    top: 49%;
    right: 5%;
    transition: all var(--base-animespeed) var(--animate-easeinout);

    &:before {
      content: '';
      background-color: $hovercolor;
      height: calc(1/375*100)vw;
      width: 20%;
      position: absolute;
      bottom: 200%;
      right: 0;
      transform: rotate(45deg);


    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;

    transition: width var(--base-animespeed) var(--animate-easeinout);

  }
}


/* ボタン 大 PC */
@define-mixin m-more-btn-large $color: var(--text-secondary), $hovercolor: var(--button-text-hover) ,$hoverbgcolor: var(--color-primary)  {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 18px 0 16px;
  border: 1px solid $color;
  line-height: 1.5;

  span {
    color: $color;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed);
    text-decoration: none;

    small {
      font-size: 1.2rem;
      display: inline;
    }
  }
  svg {
    fill: $color;
    margin-left: .5em;
    transition: all var(--base-animespeed);
    width: 12px;
    height: 12px;
  }

  i {
    display: block;
    background-color: $color;
    height: 1px;
    width: 23px;
    position: absolute;
    top: 49%;
    right: 15px;
    transition: all var(--base-animespeed);

    &:before {
      content: '';
      background-color: $color;
      height: 1px;
      width: 5px;
      position: absolute;
      bottom: 2px;
      right: 0;
      transition: all var(--base-animespeed) var(--animate-easeinout);
      transform: rotate(45deg);
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;
    transition: width var(--base-animespeed) var(--animate-easeinout);
  }

  &:hover:not(.disabled):not(.finished) {
    text-decoration: none !important;
    border-color: $hoverbgcolor;
    span {
      color: $hovercolor;
    }
    svg {
      fill: $hovercolor;
    }
    i {
      background-color: $hovercolor;
      right: -7px;

      &:before {
        background-color: $hovercolor;
      }
    }
    &:before {
      width: 100%;
    }
  }
  &.disabled,&.finished {
    opacity: .4;
    cursor: default;
    background: none;
    i,svg {
      display: none;
    }
  }
}


/* ボタン 大 SP */
@define-mixin m-more-btn-large-hover $color: var(--text-secondary), $hovercolor: var(--footer-text-hover) ,$hoverbgcolor: var(--color-primary)  {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 1em 0 .8em;
  border: calc(1/375*100)vw solid $color;
  line-height: 1.5;

  span {
    color: $color;
    position: relative;
    z-index: 99;
    transition:all var(--base-animespeed);

    small {
      font-size: calc(12/375*100)vw;
      display: inline;
    }
  }
  svg {
    fill: $color;
    margin-left: .5em;
    width: calc(12/375*100vw);
    height: calc(12/375*100vw);
  }

  i {
    display: block;
    background-color: $color;
    height: 1px;
    width: calc(23/375*100)vw;
    position: absolute;
    top: 49%;
    right: 5%;
    transition: all var(--base-animespeed);

    &:before {
      content: '';
      background-color: $color;
      height: 1px;
      width: 5px;
      position: absolute;
      bottom: 2px;
      right: 0;
      transform: rotate(45deg);
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 100%;
    background-color: $hoverbgcolor;
    position: absolute;
    top:0;
    left:0;
    z-index: 0;
    transition: width var(--base-animespeed);

  }

  &.disabled,&.finished {
    opacity: .4;
    cursor: default;
    background: none;
    i,svg {
      display: none;
    }
  }


}

@define-mixin m-section-title $pc-size:4.2rem {
  text-align: center;
  font-size: calc(32/375*100)vw;
  font-family: var(--font-apex-medium);
  font-weight: normal;
  @mixin m-middle {
    font-size: 4.2rem;
  }
};