.error {
  line-height: 1.5;
  section {
    padding: calc(60/375*100vw) 0;
  }
  h1,
  h2 {
    color: var(--color-primary);
    text-align: center;
    font-weight: normal;
  }
  h1 {
    font-size: calc(42/375*100vw);
    font-family: var(--font-apex-bold);
  }
  h2 {
    font-family: var(--font-tsukushi);
    font-size: calc(20/375*100vw);
    margin: 1em auto;
  }
  &-description {

  }
  &-button {
    @mixin m-more-btn-large-hover;
    display: block;
    width: calc(267/375*100vw);
    margin: calc(40/375*100vw) auto 0;
  }
}