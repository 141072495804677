@mixin m-middle {
  .error {
    section {
      padding: 80px 0;
    }
    h1 {
      font-size: 5.6rem;
    }
    h2 {
      font-size: 3.2rem;
      margin: .5em auto 1em;
    }
    &-description {
      text-align: center;
    }
    &-button {
      @mixin m-more-btn-large;
      width: 267px;
      display: block;
      margin: 60px auto 0;
    }
  }
}
